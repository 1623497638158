<template>
  <v-dialog
    transition="scale-transition"
    persistent
    scrollable
    v-model="open"
    max-width="500px"
  >
    <v-card outlined>
      <v-card-title class="title font-weight-bold">
        {{ $t("e_test.code_of_conduct") }}
        <div style="position: absolute; right: 25px;">
          <v-icon :disabled="disableButton" @click="toggle">mdi-close</v-icon>
        </div>
      </v-card-title>
      <v-divider />
      <v-card-text style="height: 500px; overflow-y: auto;">
        <span v-html="dataCOC" />
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-row no-gutters justify="end">
          <v-btn
            :disabled="disableButton"
            outlined
            small
            color="primary"
            depressed
            @click="toggle"
          >
            {{ $t("app.cancel") }}
          </v-btn>
          <v-spacer />
          <v-btn
            :disabled="disableButton"
            :loading="loadingStart"
            small
            dark
            class="gradient-primary"
            depressed
            @click="startEtest"
          >
            {{ $t("app.start") }}
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { getCOCGlobal, etestStudentStart } from "@/api/admin/academic/eTest";

export default {
  props: {
    open: Boolean,
    data: Object
  },
  watch: {
    open(value) {
      if (value) {
        this.getCOC();
      }
    }
  },
  data() {
    return {
      disableButton: false,
      loadingStart: false,
      dataCOC: ""
    };
  },
  methods: {
    async startEtest() {
      this.disableButton = true;
      this.loadingStart = true;
      try {
        const response = await etestStudentStart({
          etest_class: this.data.etest_class
        });
        if (response.data.code) {
          this.openEtest(this.data.etest_class);
        } else {
          this.snackBar(false, response.data.message);
        }
      } catch (error) {
        this.snackBar(false, error);
      }
      this.disableButton = false;
      this.loadingStart = false;
    },
    snackBar(isSuccess, msg) {
      this.$store.commit("CALL_SNACKBAR", {
        msg: msg,
        color: isSuccess ? "success" : "error"
      });
    },
    async getCOC() {
      this.dataCOC = "";
      this.disableButton = true;
      try {
        const response = await getCOCGlobal({
          etest_class: this.data.etest_class
        });
        if (response.data.code) {
          this.dataCOC = response.data.data.regulation_etest_class;
        } else {
          this.snackBar(false, response.data.message);
        }
      } catch (error) {
        this.snackBar(false, error);
      }
      this.disableButton = false;
    },
    toggle() {
      this.$emit("toggleDialog");
    },
    openEtest(etestClass) {
      this.$emit("start", etestClass);
    }
  }
};
</script>
